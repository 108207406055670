import { useState } from 'react';
import Loading from 'react-fullscreen-loading';
const VetPayments = () => {
    const [loading, setLoading] = useState(false);
    return (
        <>
            <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
            <div className="w-full flex flex-col gap-6 ">
                <div className="w-full flex flex-col gap-2">
                    <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
                        Payments
                    </h6>
                    <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
                        Payments Module Is In Progress
                    </p>
                </div>
            </div>
        </>
    )
}

export default VetPayments