import { CircularProgress, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';

import Loading from 'react-fullscreen-loading';
import Modal from '@mui/material/Modal';
import Pagination from '@mui/material/Pagination';
import PetDetails from '../../Adoption/AvailablePets/PetDetails';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { callAxios } from '../../../utils/axios';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';
import { useNavigate } from 'react-router';

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: 'Public Sans',
  fontSize: '14px',
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#1D1F2C',
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontFamily: 'Public Sans',
  fontSize: '14px',
  fontWeight: 500, // Customize cell text styling
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#667085',
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#fff', // Customize table head row background color
  border: 'none',
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: '10px',
}));

const OrdersTable = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [dataRange, setDataRange] = useState('');
  const [checked, setChecked] = useState(false);

  const getAllOrdres = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/order/getAllOrders?page=${page}&limit=10&search=${searchText}`
      );
      console.log(data);
      setOrders(data.data);
      setTotalPages(data.totalPage);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastMessage(error.message, 'error');
    }
  };

  const changeOrderStatus = async (e: any, id: string) => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'put',
        `dashboard/order/changeOrderStatus/${id}`,
        {
          status: e.target.value,
        }
      );
      console.log(data);

      setLoading(false);
      toastMessage(data.message, 'success');
      setChecked(!checked);
    } catch (error) {
      setLoading(false);
      toastMessage(error.message, 'error');
    }
  };

  useEffect(() => {
    getAllOrdres();
  }, [page, searchText, checked]);

  return (
    <>
      {/* <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" /> */}
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-col gap-2">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            Orders
          </h6>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
            Orders
          </p>
        </div>
        <div className="w-full bg-white rounded-lg shadow p-4 px-8 flex justify-between gap-2">
          <div className="w-1/2 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
            <img src="/assets/icons/search.svg" alt="" className="" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
          <div className="w-full">
            {loading ? (
              <div className="w-full flex justify-center items-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <Table
                sx={{
                  borderCollapse: 'separate !important',
                  width: '100%',
                }}
              >
                <TableHead>
                  <CustomTableHeadRow>
                    <CustomTableHeadCell>Products</CustomTableHeadCell>
                    <CustomTableHeadCell>Vet</CustomTableHeadCell>
                    <CustomTableHeadCell>User</CustomTableHeadCell>
                    <CustomTableHeadCell>Date</CustomTableHeadCell>
                    <CustomTableHeadCell>Payment</CustomTableHeadCell>
                    <CustomTableHeadCell>Payment Id</CustomTableHeadCell>
                    <CustomTableHeadCell>Status</CustomTableHeadCell>
                  </CustomTableHeadRow>
                </TableHead>
                <TableBody>
                  {orders.map((order: any) => {
                    return (
                      <CustomTableBodyRow key={order?._id}>
                        <CustomTableBodyCell>
                          {order?.products?.map((product) => {
                            return (
                              <p className="">{product?.productId?.name}</p>
                            );
                          })}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {order?.vet?.fullname}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {order?.user?.fullname}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {moment(order?.created_at).format(
                            'DD MMM YYYY hh:mm a'
                          )}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          <p
                            className={`${
                              order?.paid ? 'text-green-500' : 'text-red-500'
                            }`}
                          >
                            {order?.paid ? 'Paid' : 'Unpaid'}
                          </p>
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {order?.paymentIntentId}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          <Select
                            size="small"
                            value={order?.status}
                            onChange={(e) => changeOrderStatus(e, order?._id)}
                            sx={{
                              width: '180px',
                            }}
                          >
                            <MenuItem value="PROCESSING">Processing</MenuItem>
                            <MenuItem value="SHIPPED">Shipped</MenuItem>
                            <MenuItem value="DELIVERED">Delivered</MenuItem>
                          </Select>
                        </CustomTableBodyCell>
                      </CustomTableBodyRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </div>
          <div className="w-full flex justify-between items-center p-4">
            <p className="publicSans text-sm font-medium text-[#667085] leading-5">
              {dataRange}
            </p>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdersTable;
