/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { callAxios } from '../../../utils/axios';
// Table Imports
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';
import { useNavigate } from 'react-router';

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: 'Public Sans',
  fontSize: '13px',
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#1D1F2C',
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontFamily: 'Public Sans',
  fontSize: '14px',
  fontWeight: 500, // Customize cell text styling
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#667085',
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#fff', // Customize table head row background color
  border: 'none',
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: '10px',
}));

const UsersTable = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [userType, setUserType] = useState('');
  const [accountType, setAccountType] = useState('')
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [count, setCount] = useState(0);
  const [dataRange, setDataRange] = useState('');

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/dashUser/getAllUsers?page=${page}&limit=10&search=${searchText}&userType=${userType}&accountType=${accountType}`
      );
      console.log(data);

      setUsers(data?.data);
      // setCount(data?.totalData);
      setTotalPages(data?.totalPage);
      setDataRange(
        'Showing' +
          (data?.currentPage * 10 - 9) +
          '-' +
          data?.currentPage * 10 +
          ' of ' +
          data?.totalData
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };
  const handleEnableDisable = async (event, id: any) => {
    console.log(event.target.checked, id);

    try {
      setLoading(true);
      const { data } = await callAxios(
        'put',
        `dashboard/dashUser/updateUser?id=${id}`,
        {
          active: event.target.checked,
        }
      );
      toastMessage(
        `User ${event.target.checked ? 'Disabled' : 'Enabled'} successfully`,
        'success'
      );
      setUsers((prev) =>
        prev.map((user) => {
          if (user._id === id) return data?.data;
          else return user;
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [page, searchText, userType]);

  return (
    <>
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-col gap-2">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            User Management
          </h6>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
            User Management
          </p>
        </div>
        <div className="w-full bg-white rounded-lg flex justify-between items-center shadow p-4 px-8 ">
          <div className="w-1/2 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
            <img src="/assets/icons/search.svg" alt="" className="" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
         <div className='w-[33%] flex justify-around'>
         <select
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
            className="p-2 border rounded-full  outline-none px-4 border-gray-500 text-gray-500 text-sm"
          >
            <option value="" className="text-gray-400" disabled>
              Category
            </option>
            <option value="All" className="text-gray-700">
              All
            </option>
            <option value="VET" className="text-gray-700">
              Vet
            </option>
            <option value="ADOPTION" className="text-gray-700">
              Adoption
            </option>
            <option value="PETOWNER" className="text-gray-700">
              Pet Owner
            </option>
            <option value="TAXIPROVIDER" className="text-gray-700">
              Taxi Provider
            </option>
          </select>
          <select
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
            className="p-2 border rounded-full  outline-none px-4 border-gray-500 text-gray-500 text-sm"
          >
            <option value="" className="text-gray-400" disabled>
              Status
            </option>
            <option value="All" className="text-gray-700">
              All
            </option>
            <option value="true" className="text-gray-700">
              Enable
            </option>
            <option value="false" className="text-gray-700">
              Disable
            </option>
          </select>
         </div>

        </div>
        <>
          {loading ? (
            <div className="w-full flex justify-center items-center">
            <CircularProgress color='secondary'  />
            </div>
          ) : (
            <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
              <div className="w-full">
                <Table
                  sx={{
                    borderCollapse: 'separate !important',
                    width: '100%',
                  }}
                >
                  <TableHead>
                    <CustomTableHeadRow>
                      <CustomTableHeadCell>Logo</CustomTableHeadCell>
                      <CustomTableHeadCell>Center Name</CustomTableHeadCell>
                      <CustomTableHeadCell>Full Name</CustomTableHeadCell>
                      <CustomTableHeadCell>Email</CustomTableHeadCell>
                      <CustomTableHeadCell>Address</CustomTableHeadCell>
                      <CustomTableHeadCell>Phone No</CustomTableHeadCell>
                      <CustomTableHeadCell>Category</CustomTableHeadCell>
                      <CustomTableHeadCell>Action</CustomTableHeadCell>
                      <CustomTableHeadCell>
                        <div className="flex justify-center">
                          Enable/Disable
                        </div>
                      </CustomTableHeadCell>
                    </CustomTableHeadRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user: any) => {
                      return (
                        <CustomTableBodyRow key={user?._id}>
                          <CustomTableBodyCell>
                            <img
                              src={
                                user?.profilePicture
                                  ? user.profilePicture
                                  : '/assets/images/user-logo.png'
                              }
                              alt=""
                              className="w-8 h-8 rounded-full"
                            />
                          </CustomTableBodyCell>
                          <CustomTableBodyCell>
                            {user?.centerName}
                          </CustomTableBodyCell>
                          <CustomTableBodyCell>
                            {user?.fullname}
                          </CustomTableBodyCell>
                          <CustomTableBodyCell>
                            {user?.email}
                          </CustomTableBodyCell>
                          <CustomTableBodyCell>
                            {user?.address}
                          </CustomTableBodyCell>
                          <CustomTableBodyCell>{` (${user?.countryCode}) ${user?.phone}`}</CustomTableBodyCell>
                          <CustomTableBodyCell>
                            {user?.userType}
                          </CustomTableBodyCell>
                          <CustomTableBodyCell>
                            <button
                              onClick={() =>
                                navigate(
                                  `/admin/user-details?userId=${user?._id}&userType=${user?.userType}`
                                )
                              }
                              className="text-white publicSans text-sm font-medium leading-5 bg-[#1F9CB9] py-1 px-2 rounded "
                            >
                              Details
                            </button>
                          </CustomTableBodyCell>
                          <CustomTableBodyCell>
                            <div className="flex justify-center">
                              <Switch
                                checked={user?.active}
                                onChange={(e) =>
                                  handleEnableDisable(e, user?._id)
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                                color="secondary"
                              />
                            </div>
                          </CustomTableBodyCell>
                        </CustomTableBodyRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <div className="w-full flex justify-between items-center p-4">
                <p className="publicSans text-sm font-medium text-[#667085] leading-5">
                  {dataRange}
                </p>
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  color="secondary"
                  onChange={(e, value) => setPage(value)}
                />
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default UsersTable;
