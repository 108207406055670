import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { IconButton, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import Loading from 'react-fullscreen-loading';
import { callAxios } from '../../../utils/axios';
import { countries } from '../../../constants/countries';
import { toastMessage } from '../../../utils/toast';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

enum StatusEnum {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

type FormProps = {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  countryCode: string;
  phone: number;
  vehicleNumber: string;
  status: StatusEnum;
};

const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },
};

const DriverDetails = () => {
  const [errors] = useState({
    password: false,
    confirmPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [image, setImage] = useState('');
  // const handleImageChange = (e: any) => {
  //   setImage(e.target.files[0]);
  // };
  const [defaultValues, setDefaultValues] = useState(null);

  const [searchParams] = useSearchParams();

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const getUser = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/driver/getDriver?id=${searchParams.get('userId')}`
      );
      console.log(data);
      const values = { ...data?.data };
      values.password = '';
      setDefaultValues({
        ...values,
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong.',
        'error'
      );
    }
  };

  useEffect(() => {
    if (searchParams.get('userId') && searchParams.get('userType')) {
      getUser();
    }
  }, []);

  const onSubmit = async (values: FormProps) => {
    try {
      setLoading(true);
      // let result;
      // if (image) {
      //   const formData = new FormData();
      //   formData.append('file', image);
      //   result = await callAxios('post', 'app/user/uploadImage', formData);
      //   console.log(result);
      // }
      // console.log(result?.data?.url);

      const payload: any = {
        ...values,
        userType: 'DRIVER',
        // profilePicture: image
        //   ? result?.data?.url
        //   : defaultValues?.profilePicture,
      };
      delete payload.confirmPassword;

      const { data } = await callAxios(
        'put',
        `dashboard/driver/updateDriver?id=${searchParams.get('userId')}`,
        payload
      );
      console.log(data);
      toastMessage('Account Updated Successfully', 'success');
      setLoading(false);
      // navigate('/admin/user-management');
      navigate('/driver-management');
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      {defaultValues && (
        <div className="w-full flex flex-col gap-4">
          <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
            <div className="w-full bg-white rounded-lg shadow p-4 flex flex-col gap-4">
              <h6 className="text-tertiary publicSans text-base font-semibold ">
                DRIVER
              </h6>
              <div className="w-full grid grid-cols-3 gap-4">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Username
                  </p>
                  <TextFieldElement
                    name="username"
                    fullWidth
                    size="small"
                    placeholder="Username"
                    sx={styles.root}
                    required
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Email
                  </p>
                  <TextFieldElement
                    name="email"
                    fullWidth
                    size="small"
                    placeholder="Email"
                    sx={styles.root}
                    disabled
                    type="email"
                  />
                </div>
              </div>
              <div className="w-full grid grid-cols-2 gap-4">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Country Code
                  </p>
                  <SelectElement
                    options={countries.map((country) => {
                      return {
                        id: country.phone,
                        label: `${country.label} - (${country.code})`,
                      };
                    })}
                    name="countryCode"
                    fullWidth
                    size="small"
                    placeholder="Country Code"
                    sx={styles.root}
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Phone
                  </p>
                  <TextFieldElement
                    name="phone"
                    placeholder="Enter Phone Number"
                    type="number"
                    required
                    size="small"
                    sx={styles.root}
                    fullWidth
                    validation={{
                      validate: (value) => {
                        let regex = /^[1-9]\d+$/;
                        if (regex.test(value)) {
                          return true;
                        } else {
                          return 'The phone number should not start with zero. Please enter a valid phone number';
                        }
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-full grid grid-cols-2 gap-4">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Password
                  </p>
                  <TextFieldElement
                    name="password"
                    size="small"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Your password"
                    sx={styles.root}
                    validation={{
                      validate: (value) => {
                        console.log(value);
                        if (value === '') {
                          return;
                        }
                        let regex = /^(?=.*[0-9])(?=.*[@#$%^&+=!])(.{8,})$/;
                        if (regex.test(value)) {
                          return true;
                        } else {
                          return 'Password must contain atleast 8 characters and a special character and a number (0-9) ';
                        }
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Re-type Password
                  </p>
                  <TextFieldElement
                    name="confirmPassword"
                    size="small"
                    fullWidth
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Your password"
                    sx={styles.root}
                    validation={{
                      validate: (
                        value,
                        values: {
                          password: string;
                          confirmPassword: string;
                        }
                      ) => {
                        console.log(values);
                        if (values?.password === '') {
                          return;
                        }

                        if (value === values?.password) {
                          console.log('Password Matched');

                          return true;
                        } else {
                          return 'Password does not match';
                        }
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            onMouseDown={handleMouseDownConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={errors.confirmPassword}
                    // onBlur={confirmPasswordBlurHandler}
                    // helperText={
                    //   errors.confirmPassword && "Password does not match"
                    // }
                  />
                </div>
              </div>
              {/* <div className="w-full flex flex-col gap-1 ">
                <h6 className="text-tertiary publicSans text-base font-semibold ">
                  Profile picture / Center logo
                </h6>
                <div className="w-full border border-dotted border-[#E0E2E7] bg-[#F9F9FC] rounded-lg flex items-center justify-center py-6">
                  <div className="flex flex-col gap-4 items-center">
                    {defaultValues?.profilePicture ? (
                      <img
                        src={defaultValues?.profilePicture}
                        alt="picture"
                        className="w-8 h-8 rounded-lg "
                      />
                    ) : (
                      <div className="rounded-lg bg-[#8CC8E4] p-3 ">
                        <img
                          src="/assets/icons/picture.png"
                          alt="icon"
                          className="w-4 h-4"
                        />
                      </div>
                    )}

                    <p className="publicSans text-sm font-normal leading-5 text-gray-400">
                      Drag and drop image here, or click add image
                    </p>
                    <Button
                      className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5 "
                      component="label"
                      variant="contained"
                      color="secondary"
                    >
                      Add Image
                      <VisuallyHiddenInput
                        type="file"
                        required
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Button>
                  </div>
                </div>
              </div> */}
              <div className="w-full flex justify-end pt-4">
                <button className="publicSans text-sm font-semibold leading-5 bg-secondary rounded-lg p-3 text-white">
                  Save Changes
                </button>
              </div>
            </div>
          </FormContainer>
        </div>
      )}
    </>
  );
};

export default DriverDetails;
