import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import Theme from "./utils/theme";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { ConfirmProvider } from "material-ui-confirm";
import { store } from "./store/store";
import Sidebar from "./components/Layout/Sidebar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <ConfirmProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb" >
            <Sidebar>
              <App />
            </Sidebar>
          </LocalizationProvider>
        </ConfirmProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
